<template>
  <div id="tags-academy">
    <div @click="deleteEvent()" class="tag">{{ tag }} <span>x</span></div>
  </div>
</template>

<script>
export default {
  name: 'tags_academy',
  props: {
    tag: String
  },
  methods: {
    deleteEvent: function () {
      this.$emit('deleteTag', {tag: this.tag})
    }
  }
}
</script>

<style scoped>

.tag {
  display: inline-block;
  background-color: #007bff;
  padding: 0 .7rem 0 .7rem;
  border-radius: 10px;
  width: auto;
  font-size: .7rem;
  color: #fff;
  cursor: pointer;
}

.tag span {
  margin-left: 2px;
  display: none;
}

.tag:hover span {
  display: inline-block;
}

</style>